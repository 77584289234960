import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Seo from '../seo'

import { getVideosData } from 'hooks'
import { formatVideoData } from 'utils'

import './layout.css'
import * as styles from './layout.module.scss'

const Layout = ({ children, title, id, background }) => {
	const videosList = getVideosData()
	const { posters, videos } = useMemo(() => {
		return formatVideoData(videosList)
	}, [videosList])
	const poster = useMemo(
		() => posters.find(src => src.includes(`poster_inline_${id}`)),
		[posters, id]
	)
	const video = useMemo(
		() => videos.find(src => src.includes(`Bg_inline_S${id}`)),
		[videos, id]
	)
	return (
		<>
			<Seo title={title} />
			<div
				className={`${styles.container} ${
					background ? styles[background] : null
				}`}
			>
				{id && (
					<video poster={poster} className={styles.video} autoPlay loop muted>
						<source src={video} type='video/mp4' />
					</video>
				)}
				{children}
			</div>
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string.isRequired,
	id: PropTypes.number,
	background: PropTypes.string,
	inline: PropTypes.bool
}

export default Layout
